import React from 'react';
import DeckModel from '../../../models/DeckModel';
import Alpha from '../../../assets/images/alpha.png'
class AlphaWarning extends React.Component  {
      constructor(props){
        super(props);
        this.state = {}
      }

      render(){
        if(!DeckModel.showAlpha) return null;
        return (
          <div className='alpha-image-container' onClick={()=>{
              DeckModel.showAlpha = false;
              window.update();
          }}>
            <img className='alpha-image' src={Alpha} alt="alpha"/>
            <div className='alpha-warning-text'>
                This app is currently still in development..
            </div>
          </div>
        )
      }
 }

 export default AlphaWarning