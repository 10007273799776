import React, { Component } from 'react';
import logo from '../../../assets/images/qgTitle.png'
class Title extends Component {
      constructor(props){
        super(props);
        this.state = {
            style:{
                opacity:0,
                transform: 'translateY(-80px)'
            }
        }
        setTimeout(()=>{
            this.setState({
                style:{
                    opacity:1
                }
            })
        }, 100)
      }

      render() {
        return (
            <div className="qg-title-container" id="nameFrame" style={this.state.style}>
                <div className="tit-img-container">
                    <img alt='logo' src={logo} className='start-title-image'/>
                </div>
            </div>
        )
      }
 }

export default Title
