import React, { Component } from 'react';
import AppSock from '../models/AppSock';
import GlobalValues from '../models/GlobalValues'

function getAnimationCtx() {
  const anim = document.getElementById('animFrame');
  if (anim && anim.contentWindow && anim.contentWindow.exportRoot) {
    return anim.contentWindow.exportRoot;
  }
  return null;
}

function stopWriting() {
  const anim = getAnimationCtx();
  if (anim && anim.currentFrame === 83 && anim.quillbot.currentFrame === 29) {
    anim.quillbot.play();
  } else {
    setTimeout(stopWriting, 200);
  }
}

function startWriting() {
  const anim = getAnimationCtx();
  if (anim && anim.currentFrame === 83 && anim.quillbot.currentFrame === 0) {
    anim.quillbot.play();
  } else {
    setTimeout(startWriting, 200);
  }
}


class Feedback extends Component {
      constructor(props){
        super(props);
        this.state = {
          feedbackSubmitted: false,
          startedWriting: false,
        };
        this.isWriting = false;
        this.typingTimer = 0;
      }

      getReady() {
        console.log("getting ready")
        if (!this.state.startedWriting) {
          const anim = getAnimationCtx();
          setTimeout(this.getReady.bind(this), 200);
          if (anim !== null && anim.currentFrame === 26) {
            anim.play();
            this.setState({ startedWriting: true });
          }
        }
      }
    
      isTyping = () => {
        if (!this.isWriting) {
          startWriting();
        }
        this.isWriting = true;
    
        if (this.typingTimer !== null) {
          clearTimeout(this.typingTimer);
        }
    
        this.typingTimer = setTimeout(() => {
          if (this.isWriting) {
            stopWriting();
          }
          this.isWriting = false;
        }, 1800);
      };
    
      sendFeedback = () => {
        var el = document.getElementById("feedbackInput")
        var val = el.value;
        AppSock.sendFeedback(val, ()=>{
          el.value = "Message sent. Thank you!"
        })
      };

  

      render(){
        var style = {}
        if(this.props.parent.state.openFeedback){
          style = {
            transform: "translateY(0vh)"
          }
        }else{
          style = {
            transform: "translateY(100vh)"
          }
        }

        return (
         <div className='feedback-container' style={style}>
           <div className='close-btn' onClick={this.props.parent.toggleFeedback.bind(this.props.parent)}>
              ✕ Close
           </div>
            <div className='feedback-content'>
              <div className='animation-container'>
                <iframe
                      title="quillanim"
                      src="/mascot/quillbot_feedback_animation/quillAnim.html"
                      className="anim-frame"
                      id="animFrame"
                      scrolling="no"
                      style={{
                        border: 'none',
                        height: '40vh',
                        bottom: 10
                      }}
                    />
              </div>
              <div className='prompt-container'>
                <div className='feedback-info'>
                  <div className='feedback-subtitle'>
                        How am I doing?
                  </div>
                  <div className='feedback-message'>
                      I'm in early stage development.
                      Your feedback will influence how I grow.
                  </div>
                </div>
                <textarea 
                className='feedback-textarea-input' 
                placeholder="Write your thoughts here"
                id="feedbackInput"
                onFocus={this.getReady.bind(this)}
                onChange={() => {
                  this.isTyping();
                  if (this.state.feedbackSubmitted) {
                    this.setState({ feedbackSubmitted: false });
                  }
                }}
                ></textarea>
                <div className='feedback-buttons'>
                    <div className='discord-btn' onClick={()=>{
                      window.open(GlobalValues.DISCORD_LINK)
                    }}>
                      Use Discord
                    </div>
                    <div className='feedback-send-btn' onClick={this.sendFeedback.bind(this)}>
                      Send
                    </div>
                </div>
              </div>
            </div>
         </div>
         )
      }
 }

export default Feedback
