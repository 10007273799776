import React, { Component } from 'react';
import GlobalValues from '../../models/GlobalValues';
//import InputHandler from './models/InputHandler'

import AppSock from '../../models/AppSock'
import LoadingButton from '../LoadingButton'
import Title from './parts/Title'
import InputBox from './parts/InputBox'
import SubmitBtn from './parts/SubmitBtn'
import StartAnimation from './parts/StartAnimation'
import UpdateCountdown from './parts/UpdateCountdown'
class StartPage extends Component {
      constructor(props){
        super(props);
        this.state = {
        }
      }

      openGenerated(){
        console.log("TODO!")
      }

      renderButtons(){
          if(!GlobalValues.submitted){
            return null;
          }
          return (
              <div className='loading-buttons-container' id='loadingButtonsContainer'>
                <LoadingButton title="Take Quiz" onClick={()=>{}} dynamic={true} value={AppSock.quiz_progress}/>
                <LoadingButton title="Flash Cards" dynamic={true} value={AppSock.card_progress} onClick={()=>{
                    // AppModel.fade(()=>{
                    //     AppModel.page = "interact"
                    //     window.history.pushState('page2','interact','/interact?study_id='+AppSock._uid)
                    //     window.update()
                    //     DeckModel.moreCards()
                    // })
                }} delay={150}/>
              </div>
          )
      }

      render() {
        return (
            <div className='everything' id='everything'>
                <Title/>  
                <div id="tryDiv" className="floaty-box input-container">
                    <div className='inner-container'>
                        <InputBox charsLeft={this.state.charsLeft}/>
                        <SubmitBtn/>
                        <StartAnimation/>   
                    </div>
                </div>
                {this.renderButtons()}
                <UpdateCountdown/>
            </div>
        )
      }
 }

export default StartPage
