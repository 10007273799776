import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

var update = ()=>{
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

update()
window.update = update

var timeout = null;
var delta = 300;

window.addEventListener('resize', ()=>{
  clearTimeout(timeout)
  timeout = setTimeout(()=>{
    window.update()
  }, delta) 
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
