import React from 'react';
import DeckModel from '../../../models/DeckModel'
import TinyCard from '../cards/TinyCard'
import GlobalValues from '../../../models/GlobalValues';

class CatalogView extends React.Component {
      renderBackDrop(){
          if(DeckModel.focusCard){
            return (
                <div className="backdrop">
                    <div className="back-clickable" onClick={DeckModel.hidePopUp}>
                    </div>
                </div>
            )
          }
          return null;
      }

      renderAddMoreBtn(){
        var container = document.getElementById("cardContainer")
        if(!container) return;
        var bounds = container.getBoundingClientRect()
        var cWidth = bounds.width;
        var zoom = window.devicePixelRatio;
        var unit = (cWidth/1000)*zoom;
        var cpr = GlobalValues.CARD_PER_ROW
        var height = 120
        var top = (40+(Math.ceil((DeckModel.cards.filter(c=>!c.trashed).length)/cpr)*height))*unit
        var left = 110*unit
        var text = DeckModel.full_deck.length > 0?"+ Add More Cards":""
          return (
              <div className='add-more-cards' onClick={DeckModel.moreCards.bind(DeckModel)} style={{top:top, left: left}}>
                  {text}
              </div>
          )
      }

      

      render() {
            var cards = DeckModel.cards.map((card)=>{
                return (<TinyCard key={"tiny_card_"+card.card_id} data={card}/>)
            });
            // var extraPadding = 0 // Math.max(Math.floor((DeckModel.cards.filter((card)=>!card.trashed).length)/4) - 3, 0)*window.screen.availWidth/5;
            //console.log("extra", extraPadding)
            
            var style = {}
            var title = document.getElementById("titleContainer")
            if(title){
                var bounds = title.getBoundingClientRect()
                if(bounds.height > 70){
                    style.marginTop = 120
                }
            }
            return(
                <div className="fast-div flash-pop-container" style={style}>
                    <div className='tiny-cards-container' id='cardParent'>
                        <div className="scroll-box" id='cardContainer' style={{height: DeckModel.bottom}}>
                        {this.renderAddMoreBtn()}
                        {cards}
                        </div>
                    </div>
                    {this.renderBackDrop()}
                </div>
            )
      }
 }
 export default CatalogView

 