import React from 'react';
import DeckModel from '../../../models/DeckModel'
import Esc from '../../../assets/images/Esc.png'
import TrashEmpty from '../../../assets/images/trash-empty.png'
import TrashFull from '../../../assets/images/trash-full.png'
import Left from '../../../assets/images/Left.png'
import Down from '../../../assets/images/Down.png'
import Right from '../../../assets/images/Right.png'
import Nerd from '../../../assets/images/nerd.png'
import Annotator from '../../../models/Annotator'
import upload from '../../../assets/images/upload.png'
import AppSock from '../../../models/AppSock'

class ActionBar extends React.Component {
      constructor(props){
        super(props);
        this.state = {
          trashStyle:{},
          showAlphaImage: true
        }
      }
      
      shakeTrash(){
        this.setState({
          trashStyle:{
            transform: 'rotate(3deg)'
          }
        })
        setTimeout(()=>{
          this.setState({
            trashStyle:{
              transform: 'rotate(-3deg)'
            }
          })
        },150)

        setTimeout(()=>{
          this.setState({
            trashStyle:{
              transform: 'rotate(0deg)'
            }
          })
        },300)
      }
      
      renderAnnotatorUpload(){
        if(!Annotator.userKey) return null
        return (
            <div className='annotate-upload'>
              <img src={upload} className='upload-img' alt='upload' onClick={()=>{
                AppSock.uploadCardRankings()
              }}/>
            </div>
        )
      }

      render() {
        var trash = DeckModel.hasTrash?TrashFull:TrashEmpty;
        var cname = DeckModel.hasTrash?'trash-full':'trash-empty'
        if(DeckModel.display === "catalog"){
            return (
              <div className="tool-container">
                <div className='tool-contents'>
                  <div className='study-button' onClick={DeckModel.changeMode.bind(DeckModel, "study")}>
                    <img alt='nerd' src={Nerd} className='nerd-img'/>
                    <div className="inst-text"> Study Cards </div>
                  </div>
                  {this.renderAnnotatorUpload()}
                  <div className={cname}>
                    <img alt='trash' src={trash} className='trash-icon' style={this.state.trashStyle} onClick={()=>{
                      if(DeckModel.hasTrash){
                        DeckModel.showAlpha = true;
                        window.update()
                      }else{
                        this.shakeTrash();
                      }
                    }}/>
                  </div>
                </div>
              </div>
            );
          }else if(DeckModel.display === "study"){
            return (
              <div className="tool-container" style={{bottom: '2%'}}>
                <img alt='escape' src={Esc} className="arrows-img" onClick={()=>{
                  DeckModel.changeMode('catalog')
                }}/>
                <div className="inst-text2"> Back </div>
                <img alt='left' src={Left} className="arrows-img" onClick={DeckModel.prevCard.bind(DeckModel)}/>
                <div className="inst-text2"> Prev </div>
                <img alt='down' src={Down} className="arrows-img" onClick={DeckModel.flipFocus.bind(DeckModel)}/>
                <div className="inst-text2"> Flip </div>
                <img alt='right' src={Right} className="arrows-img" onClick={DeckModel.nextCard.bind(DeckModel)}/>
                <div className="inst-text2"> Next </div>
                {/* <img src={Up} className="arrows-img" onClick={DeckModel.trashCard}/>
                <div className="inst-text"> Trash </div> */}
              </div>
            );
          }else{
            return null;
          }
      }
 }

 export default ActionBar