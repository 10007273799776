
import AppSock from './AppSock'

var Annotator = new(function(){
   this.userKey = null;
   this.studies_done = 0;
   this.card_orderings = 0;
   this.cards_ordered = 0;
   this.questions_made = 0;
   this.questions_rejected = 0;
   this.init = ()=>{
      if(this.userKey === null){
         this.userKey = prompt("Please enter your secret name")
         if(this.userKey.length < 5){
            this.userKey = null;
            this.init()
         }
      }
      console.log("INIT ANNOTATION")
      setTimeout(()=>{
         AppSock.listenForLabels();
      }, 1000)
   }
})()

window.Annotator = Annotator
export default Annotator
