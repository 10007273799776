import React from 'react';
import DeckModel from '../../../models/DeckModel'
import Inputs from '../../../models/Inputs'
import LoadingButton from '../../LoadingButton'

import AppSock from '../../../models/AppSock'
class TitleBar extends React.Component {
      constructor(props){
        super(props);
        this.state = {
          toInput:false,
          name:props.testName,
          openOptions:false
        }
        this.options =  [
            ["Show Back Title",()=>{DeckModel.showBackTitle = !DeckModel.showBackTitle},true],
            ["Enable Swap Animations",()=>{DeckModel.noAnims = !DeckModel.noAnims},false],
            ["Enable Dynamic Imaging",()=>{DeckModel.applyImages = !DeckModel.applyImages},false]
        ]
      }

      _onKeyPress(e){
        if (e.key === 'Enter') {
          this.submitNameChange(e.target.value)
        }
      }

      toInput(){
          this.setState({toInput:true});
          Inputs.disableHotKeys();
      }

      submitNameChange(val){
        this.setState({toInput:false,name:val});
        Inputs.enableHotKeys();
      }

      renderNewButton(){
        return null
      }

      renderFeedbackButton(){
        return (
          <div className='title-feedback-btn' onClick={this.props.parent.toggleFeedback.bind(this.props.parent)}>
            Feedback
          </div>
        )
      }

      renderToggleModeButton(){
        var title = "Take Quiz"
        var nxtMode = "quiz"
        var width = '50%'
        if(DeckModel.display === "quiz"){
          title = "Flashcards"
          nxtMode = 'catalog'
          width = '50%'
        }

        return (<LoadingButton onLoadTitle="Making Facts" style={{maxWidth: 180, width:width, boxShadow:'none', marginTop:0, marginBottom:0, fontSize:'.75em'}} title={title} onClick={()=>{
          DeckModel.changeMode(nxtMode)
          setTimeout(window.update, 500)
        }} dynamic={true} value={AppSock.quiz_progress}/>)

      }

      renderNumItems(){
        if(DeckModel.display === "quiz"){
          var num = 0;
          if(DeckModel.quiz){
            num = DeckModel.quiz.questions.length
          }
          return (
                <div className='num-cards' style={{fontSize:"1em"}}>
                      <span>MPCs: </span><span style={{marginLeft:8}}>{num}</span>
                </div>
          )
        }else{
          return (
                <div className='num-cards'>
                  {"Cards: "+DeckModel.cards.filter(c=>!c.trashed).length}
                </div>
                )
        }
        
      }

      render() {
        var checkStyle = this.state.openOptions?{marginTop:"6.5vh"}:{marginTop:"calc(-10vh - 10vw)"};
        var options = this.options.map((opt)=>{
        //  var checked = opt[2]?checked:"";
          return(
            <div key={"opt_"+opt[1]} className="option-checkbox">
              <input className="checkbox-box" type="checkbox" name="animate" value="animate" onChange={opt[1]} checked/>
                <label className="option-label">{opt[0]}</label>
            </div>
          )
        })
        return (
          <div className="toolbar">
            <div className="option-pane">
              <div style={checkStyle} className="option-container anim">
                {options}
              </div>
            </div>
            <div className='row teal'>
              <div className='title' id='titleContainer'>
                <div className='title-name' id="titleName" onClick={this.toInput.bind(this)} contentEditable={true} suppressContentEditableWarning={true} onKeyUp={()=>{
                  var el = document.getElementById("titleName")
                  if(el.innerHTML === "<br>"){
                    console.log("yep")
                    el.innerHTML = "untitled"
                  }
                  DeckModel.name = el.innerHTML
                }}>
                    {DeckModel.name}
                </div>
                <div className="toolbar-container">
                  {this.renderNewButton()}
                  {this.renderFeedbackButton()}
                  {this.renderToggleModeButton()}
                  {this.renderNumItems()}
                </div>
              </div>
            </div>
          </div>
        );
      }
 }

 export default TitleBar