import React from 'react';
import DeckModel from '../../../models/DeckModel';
import {shuffle} from '../../../libs/utils'
import MPCAnswer from '../parts/MPCAnswer'
import EditableAnswer from '../parts/EditableAnswer'
import QuestionLabel from '../parts/QuestionLabel'
import Annotator from '../../../models/Annotator'
class QuizView extends React.Component {
      constructor(props){
        super(props);
        this.state = {
          mpc: true
        }
        if(DeckModel.quiz && DeckModel.quiz.questions.length === 0){
          DeckModel.quiz.moreQuestions()
        }
      }

      renderMPC(q){
        var ls = ['A','B','C','D']
        var letters = shuffle([...ls])
        var cletter = letters.pop()
        var answers = [[(<MPCAnswer text={q.answer} letter={cletter} correct={true} key={q.qid+"-"+cletter}/>), cletter]]
        for(var i=0; i<Math.min(letters.length, q.alts.length); i++){
          answers.push([(<MPCAnswer text={q.alts[i]} letter={letters[i]} correct={false} key={q.qid+"-"+letters[i]}/>), letters[i]])
        }
        return answers.sort((a,b)=>{
          return ls.indexOf(a[1])-ls.indexOf(b[1])
        }).map(x=>x[0])

      }


      renderQuestion(q){
        if(!q) return null
        var answers = null;
        // if(q.alts && q.alts.length > 2 && this.state.mpc){
        //   answers = this.renderMPC(q)
        // }else{
        //   answers = (<FIBAnswer answer={q.answer}/>) //this.renderFIB(q)
        // }
        answers = (<EditableAnswer question={q}/>)
        var labeler = (Annotator.userKey)?(<QuestionLabel question={q}/>):null
        return(
          <div className='question-container' key={'question-'+q.qid}>
            <div id={'question-'+q.qid} className='question-title' contentEditable="true" suppressContentEditableWarning={true}>
              {labeler}              
              {q.text}
            </div>
            <div className='answers-container'>
              {answers}
            </div>
          </div>
        )
      }

      renderMoreQuestionsButton(){
        return (
          <div className='more-questions-btn' onClick={()=>{DeckModel.quiz.moreQuestions()}}>
            + More Questions
          </div>
        )
      }

      renderOptions(){
        if(!Annotator.userKey){
          return null
        }
        // var img = this.state.mpc?fib:mpc
        return(
          <div className='quiz-option-container'>
              <div className='contributions'> Contributions: {Annotator.totalContributions} </div>
              <div className='contributions-label'> Studies Done: {Annotator.studies_done} </div>
              <div className='contributions-label'> Card Orderings: {Annotator.card_orderings} </div>
              <div className='contributions-label'> Cards Ordered: {Annotator.cards_ordered} </div>
              <div className='contributions-label'> Qs Made: {Annotator.questions_made} </div>
              <div className='contributions-label'> Qs Rejected: {Annotator.questions_rejected} </div>
              {/* <img src={img} className='quiz-type-img' onClick={()=>{
                this.setState({mpc:!this.state.mpc})
              }}/> */}
          </div>
        )
      }

      render() {
        if(!DeckModel.quiz) return (
          <div className="study-container"></div>
        );
        return(
            <div className="study-container">
              <div className='questions-container'>
                <div className='growth-pane'>
                  {DeckModel.quiz.questions.map(x=>this.renderQuestion(x))}
                  {this.renderMoreQuestionsButton()}
                </div>
              </div>
              {this.renderOptions()}
            </div>
        )
      }
 }
 export default QuizView
