import {GUID} from '../libs/utils'
import DeckModel from './DeckModel'
import Endpoints2 from '../models/Endpoints2'
import app from '@firebase/app'
import '@firebase/database';
import '@firebase/analytics'
import GlobalValues from './GlobalValues';
import {getStartAnimationCtx} from '../libs/utils'
import AppModel from '../models/AppModel'
import Annotator from './Annotator'
 // Set the configuration for your app
  // TODO: Replace with your project's config object
var database = null
window.nutt = true
setTimeout(()=>{
    if(!window.database){
        var config = {
            apiKey: "AIzaSyCu6r0NXUprQ91PrCYr7V5Mgbh-LgNN5DU",
            authDomain: "quickquiz-c7981.firebaseapp.com",
            databaseURL: "https://quickquiz-c7981-default-rtdb.firebaseio.com",
            projectId: "quickquiz-c7981",
            storageBucket: "quickquiz-c7981.appspot.com",
            messagingSenderId: "998660993894",
            appId: "1:998660993894:web:f361896c18691b72960ea7",
            measurementId: "G-9346LC99V3"
          };
        app.initializeApp(config);
        database = app.database();
        window.database = database;
        window.analytics = app.analytics();
        console.log("loaded")
        console.log("inited firebase")


        //CLEAR EVERYTHING:
        // database.ref('studies/').set(null);
        // console.log("all is gone")

    }
}, 10)

var appSpamFlag = false;
var alreadySubmitted = false;
var AppSock = new(function(){
    this._uid = GUID()
    this.mlogs = [];
    this.quiz_progress = {cur:2, total:40}
    this.card_progress = {cur:2, total:40}
    if(appSpamFlag){
        return;
    }
    appSpamFlag = true;

    this.getStudy = (uid, cb)=>{
        if(!database){
            setTimeout(this.getStudy.bind(this, uid, cb), 100)
            return;
        }
        var update_loading_bar = (all_cards)=>{
            var sufficient_bar = Math.min(GlobalValues.QG_DEPTH_LIMIT, all_cards.length)
            var completed = all_cards.filter(x=>x.facts !== null).length
            //console.log(all_cards.filter(x=>x.facts === null))
            //console.log(sufficient_bar, completed)
            if(completed >= sufficient_bar){
                this.quiz_progress = {cur:100, total: 100}
            }else{
                this.quiz_progress = {cur:Math.floor(completed+(sufficient_bar/4)), total: sufficient_bar+(sufficient_bar/4)}
            }
            window.update()
        }

        this._uid = uid;
        database.ref('studies/'+uid).once('value').then((snapshot) => {
            var data = snapshot.val()
            cb(data)
            var _cards = DeckModel.cards.concat(DeckModel.full_deck)
            var _numeric_cards = []
            var _regular_cards = []
            _cards.forEach(x=>{
                if(x.type === "numeric"){
                    _numeric_cards.push(x)
                }else{
                    _regular_cards.push(x)
                }
            })

            var all_cards = []
            for(var i=0; i<_cards.length; i++){
                if(i%4===3){
                    if(_numeric_cards.length > 0){
                        all_cards.push(_numeric_cards.shift())
                    }else{
                        all_cards.push(_regular_cards.shift())
                    }
                }else{
                    if(_regular_cards.length > 0){
                        all_cards.push(_regular_cards.shift())
                    }else{
                        all_cards.push(_numeric_cards.shift())
                    }
                }
            }

            update_loading_bar(all_cards)
            var depth = 0
            if(!data.connection){
                var ref = database.ref('studies/'+this._uid+"/connection")
                ref.set(true)
                var toLoad = all_cards.filter(x=>x.facts === null)
                var processCard = ()=>{
                    if(toLoad.length === 0) return;
                    var nxtCard = toLoad.shift()
                    nxtCard.getFactsFromReferences((facts)=>{
                        console.log(nxtCard.card_id, facts)
                        depth += 1
                        update_loading_bar(all_cards)
                        if(facts.length === 0){
                            facts = [0]
                        }
                        database.ref('studies/'+this._uid+"/deck/cards/"+nxtCard.card_id+"/facts").set(facts, (err)=>{
                            console.log(err)
                        });
                        if(depth < GlobalValues.QG_DEPTH_LIMIT){
                            processCard()
                        }
                    })
                }
                processCard()
                //console.log(toLoad)
            }
        });
    }

    // this.craftQuiz = ()=>{
    //     database.ref('studies/'+this._uid).once('value').then((snapshot) => {
    //         var study = snapshot.val()
    //         console.log(typeof(study))
    //     });
    // }

    
    this.sendFeedback = (message, cb)=>{
        var curTime = Date.now()
        database.ref('feedback/'+this._uid+"/"+curTime).set(message,(err)=>{
            if(err){
                console.log("FAILED TO SEND FEEDBACK")
            }else{
                if(cb) cb()
            }
        });

    }

    this.labelQuestion = (question, answer, label, cb)=>{
        var key = question + " | " +answer
        var tag = {
            label: label,
            time: Date.now()
        }
        database.ref('annotations/'+this._uid+"/"+Annotator.userKey+"/questions/"+key).set(tag, (err)=>{
            if(err){
                console.log("FAILED TO SEND FEEDBACK")
            }else{
                if(cb) cb()
            }
        });
    }

    this.uploadCardRankings = ()=>{
        var tag = {
            order: DeckModel.cards.map(x=>x.topic),
            time: Date.now()
        }
        database.ref('annotations/'+this._uid+"/"+Annotator.userKey+"/cardOrderings").set(tag, (err)=>{
            if(err){
                console.log("FAILED TO SEND FEEDBACK")
            }else{
                alert("uploaded card orderings")
            }
        });
    }

    this.listenForLabels = ()=>{
        console.log("listening for labels")
        if(database === null){
            setTimeout(this.listenForLabels, 200)
            return;
        }
        database.ref('annotations/').on('value', (snap)=>{
            var result = snap.val()
            var studies_done = 0
            var card_orderings = 0
            var cards_ordered = 0
            var questions_made = 0
            var questions_rejected = 0

            Object.keys(result).forEach(key=>{
                var obj = result[key]
                var labels = obj[Annotator.userKey]
                if(labels){
                    studies_done += 1
                    if(labels['cardOrderings']){
                        card_orderings += 1
                        cards_ordered += labels['cardOrderings']['order'].length
                    }
                    if(labels['questions']){
                        questions_made += Object.keys(labels['questions']).map(x=>labels['questions'][x]).filter(x=>x.label === "good").length
                        questions_rejected += Object.keys(labels['questions']).map(x=>labels['questions'][x]).filter(x=>x.label !== "good").length
                    }
                }
            })
            Annotator.studies_done = studies_done;
            Annotator.card_orderings = card_orderings;
            Annotator.cards_ordered = cards_ordered;
            Annotator.questions_made = questions_made;
            Annotator.questions_rejected = questions_rejected;

            window.update()
            //console.log("RESSIES:", result)
            //console.log("contribs: ", total_contribs)
        })
    }

    this.sendSource = (content)=>{
        if(alreadySubmitted) return;
        database.ref('studies/'+this._uid).set({
            content: content,
            status: "submitted",
            connection: false,
            created: Date.now(),
            author: Annotator.userKey
        });

        database.ref('studies/'+this._uid+"/status").on('value', (snap)=>{
            var result = snap.val()
            var status = result.status
            console.log(result)
            var ctx = getStartAnimationCtx()
            if(status === "card_genning"){
                var cur = result.cur
                var total = result.total
                var perc = cur/total
                if(ctx.currentFrame === 40){
                    ctx.children[0].play()
                }
                window.scrollElTo("inputText", perc, 5)
                this.card_progress = {cur:cur, total: total+2}
                this.quiz_progress = {cur:cur, total: total*4}
                window.update();
            }

            if(status === "made_cards"){
                var ct = this.card_progress.total+2;
                this.card_progress = {cur:ct, total: ct}
                window.update();
                ctx.play()
                DeckModel.init(this._uid)
                setTimeout(()=>{
                    AppModel.fade(()=>{
                        AppModel.page = "interact"
                        console.log("switching modes")
                        window.history.pushState('page2','interact','/interact?study_id='+this._uid)
                        //window.history.replaceState('page2','','?study_id='+this._uid)
                        console.log("hap")
                        window.update()
                        DeckModel.moreCards()
                    })
                }, 1000)
            }
        });
        
        

        Endpoints2.makeCards({content: content, uid:this._uid}, (result)=>{
            console.log("making cards..")
        })
        console.log(content)
        alreadySubmitted = true;
    }
})()

export default AppSock
