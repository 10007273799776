import React from 'react';
import PopCard from '../cards/PopCard'
import DeckModel from '../../../models/DeckModel'
class StudyView extends React.Component {
      render() {
        //DeckModel.focusCard = DeckModel.cards[1]
        return(
            <div className="study-container" id='studyContainer' onClick={DeckModel.hidePopUp.bind(DeckModel)}>
                {DeckModel.cards.reverse().map(x=>{return (<PopCard data={x} key={'card-'+x.card_id}/>)})}
            </div>
        )
      }
 }
 export default StudyView
