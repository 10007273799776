import DeckModel from './DeckModel'
import Endpoints2 from './Endpoints2';
import GlobalValues from './GlobalValues';
var DEFAULT_FACT_LEN = 12
class CardModel{
  constructor(card, i){
    this.facts = null;
    Object.keys(card).forEach(k=>{
      this[k] = card[k]
    })
    //console.log(this.facts)
    if(this.facts !==null && this.facts.length === 1 && this.facts[0]===0){ //this is just an exception made to store empty arrays in firebase
      this.facts = []
    }
    this.activeFacts = []
    if(this.facts && this.facts.length > 0){
      this.activeFacts = this.facts.slice(0,DEFAULT_FACT_LEN)
    }

    this.neverFlipped = true;
    this.flipped = false;
    this.completed = false;
    this.trashed = false;
    this.images = null;
    this.img_idx = 0
    if(typeof(this.etype) === "object" && this.etype.indexOf(12) > -1){
      this.type = "numeric"
      //console.log(this.topic)
    }

    if(!this.definition){
      this.definition = "No definition found"
    }else{
      this.definition = this.definition[Object.keys(this.definition)[0]][0]  // Use first definition (Not Perfect)
    }

    this.position = i;
    this.calcPoses();
  }

  calcPoses(){
    var width = GlobalValues.CARD_WIDTH
    var height = GlobalValues.CARD_HEIGHT
    var leftMargin = GlobalValues.LEFT_MARGIN;
    var topMargin = GlobalValues.TOP_MARGIN
    var cpr = GlobalValues.CARD_PER_ROW

    var idx = this.position;
    if(DeckModel.draggingCard && DeckModel.bufferCard>-1){
      if(idx < DeckModel.draggingCard.position){
        if(idx >= DeckModel.bufferCard){
          idx += 1
        }
      }else{
        if(idx <= DeckModel.bufferCard){
          idx -= 1;
        }
      }
    }

    this.graphics = {
      x: (idx%cpr)*(width)+leftMargin,
      y: Math.floor(idx/cpr)*height+topMargin,
      width: width,
      height: height
    }
  }

  getCoords(){
    var container = document.getElementById("cardContainer")
    var parent = document.getElementById("cardParent")

    if(container == null){
      //console.log("INITIALIZED BEFORE FIRST PAINT, MAKE A LOAD HANDLER!")
      return null;
    }
    var bounds = container.getBoundingClientRect()
    var parent_bounds = parent.getBoundingClientRect()
    var cWidth = bounds.width;
    // var zoom = window.devicePixelRatio;
    //console.log(cWidth, zoom)
    var unit = (cWidth/1000)//*zoom;
    var g = this.graphics;
    if(DeckModel.cards.indexOf(this) === DeckModel.cards.length-1){
      var bottom = g.y*unit+g.height*unit + window.screen.height/4
      DeckModel.cardBottom = bottom
      DeckModel.bottom = Math.max(bottom, window.screen.height*.9)
    }

    return {
      x: g.x*unit,
      y: g.y*unit,
      width: g.width*unit,
      height: g.height*unit,
      borderRadius: 7*unit,
      cWidth: cWidth,
      cHeight: parent_bounds.height,
      unit: unit
    }
  }

  removeFact(fact){
    var index = this.facts.indexOf(fact);
    if(index>-1){
      this.facts.splice(index,1);
    }
    index = this.activeFacts.indexOf(fact);
    if(index>-1){
      this.activeFacts.splice(index,1);
    }
  }

  toggleSelected(cb){
    this.selected = !this.selected;
    if(this.selected){
      DeckModel.selectedCards.push(this);
    }else{
      var ind = DeckModel.selectedCards.indexOf(this);
      DeckModel.selectedCards.splice(ind,1);
    }
    cb()
  }


  getFactsFromReferences(save_cb){
    var ref_wins = {}
    if(this.refs === undefined){
      this.facts = []
      save_cb([])
      return 
    }
    
    this.refs.forEach(x=>{
      if(x-1 > 0){
        ref_wins[x-1] = true
      }
      if(x+1 < DeckModel.sentences.length){
        ref_wins[x+1] = true
      }
      ref_wins[x] = true
    })

    var wins = Object.keys(ref_wins)
    wins.sort()

    var context = wins.map(idx=>{
      return DeckModel.sentences[idx].replace("\n"," ")
    })
    var size_limit = 1400
    var ttoks = 0
    var last_tok = 0
    var batches = []
    var batch = []
    var last_sent = ""
    context.forEach(s=>{
      var toks = s.length //len(sq_tokenizer(s)['input_ids'])
      ttoks += toks
      if(ttoks > size_limit){
        batches.push(batch)
        if((last_tok + toks) < size_limit){
          batch = [last_sent, s]
          ttoks = last_tok + toks
        }else{
          batch = [s]
          ttoks = toks
        }
      }else{
        batch.push(s)
      }
      last_tok = toks
      last_sent = s
    })
    batches.push(batch)
    console.log("getting facts for: "+ this.topic, batches)
    batches.forEach(b=>{
      var ctx = b.join(" ")
      Endpoints2.getFacts({context:ctx, subject:this.topic}, (facts)=>{
        var more_facts = facts.facts.filter(x=>(x[0].indexOf("?") > -1))
        console.log(more_facts)
        if(this.facts === null){
          this.facts = []
        }
        this.facts = this.facts.concat(more_facts)
        this.facts.sort((a,b)=>{return a[1] - b[1]})
        this.activeFacts = this.facts.slice(0,DEFAULT_FACT_LEN)
        DeckModel.quiz.constructQuiz()
        save_cb(this.facts)
        if(this.blink){
          this.blink()
        }
      })
    })
    return context
  }


  open(){
    if(this.neverFlipped){
      Endpoints2.getImages({subject:this.topic}, (results)=>{
        if(results.status === 500) return;
        this.images = results.images;
        if(this.images.length === 0){
          this.images = null;
        }
        window.update();
      })
    }
    this.neverFlipped = false;
    this.flipped = true;

    if(this.definition === "No definition found"){
      this.definition = "Fetching definition.."
      Endpoints2.getDefinition({subject:this.topic}, (result)=>{
        console.log(result)
        try{
          this.definition = result[0]['meanings'][0]['definitions'][0]['definition']
        }catch(e){
          this.definition = "No definition found"
        }
        window.update();
      })
    }
  }

}

export default CardModel
