import React from 'react';
import TitleBar from './parts/TitleBar'
import ActionBar from './parts/ActionBar'
import CatalogView from './views/CatalogView'
import StudyView from './views/StudyView'
import QuizView from './views/QuizView'
import DeckModel from '../../models/DeckModel'
import Fader from '../FlashCardPage/parts/Fader'
import Feedback from '../Feedback'
import AlphaWarning from '../FlashCardPage/parts/AlphaWarning'

class FlashCardPage extends React.Component {
      constructor(props){
        super(props);
        this.state = {
          openFeedback: false 
        }
      }

      getView(){
        switch(DeckModel.display){
          case "catalog": 
            return (<CatalogView/>)
          case "study":
            return (<StudyView/>)
          case "quiz":
            return (<QuizView/>)
          default:
            return null
        }
      }

      toggleFeedback(){
        this.setState({ openFeedback: !this.state.openFeedback })
      }

      render() {
        var view = this.getView()
        return (
          <div className='flashcard-container'>
            <TitleBar testName={DeckModel.name} parent={this}/>
            <AlphaWarning/>
              {view}
            <ActionBar/>
            <Feedback parent={this}/>
            <Fader fader={DeckModel}/>
          </div>
        );
      }
 }

export default FlashCardPage