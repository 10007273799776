import React, { Component } from 'react';
class ChromeWarning extends Component {
      constructor(props){
        super(props);
        this.state = {
            chrome: window.chrome || window.navigator.userAgent.match("CriOS") || /chrome/i.test( navigator.userAgent )
        }
      }

      render(){
        if(this.state.chrome) return null;
        return (
                <div className='browser-warning' onClick={()=>{
                    window.open("https://brave.com/", '_blank');
                }}>
                    This app is not supported on your browser.
                </div>
                )
      }
 }

export default ChromeWarning
