import React from 'react';
class MPCAnswer extends React.Component  {
      constructor(props){
        super(props);
        this.state = {
            showCorrectness: false
        }
      }

      render(){
        var style = {}
        if(this.state.showCorrectness){
            var bgColor = this.props.correct?"#8ae68a":"#e68e8e"
            var color = this.props.correct?"black":"gray"
            style = {
                background: bgColor,
                color: color
            }
        }
        return (
            <div className='answer-container' style={style} onClick={()=>{
                this.setState({showCorrectness:true})
            }}>
                <div className='answer-text'>{this.props.letter+")"}</div>
                <div className='answer-text'>{this.props.text}</div>
            </div>
        )
      }
 }

 export default MPCAnswer