import React from 'react';
import './App.css';
import './assets/css/defaults.css';
import './assets/css/console.css';
import './assets/css/quiz.css';
import './assets/css/parse.css';
import './assets/css/flash.css';

import StartPage from './components/StartPage/StartPage';
import ChromeWarning from './components/ChromeWarning'
import FlashCardPage from './components/FlashCardPage/FlashCardPage.js'
import AppModel from './models/AppModel'
import Fader from './components/FlashCardPage/parts/Fader'
function App() {
  var content = []
  if(AppModel.page === "start"){
    content = [<ChromeWarning key="chrome-warning"/>, <StartPage key="start-page"/>]
  }else{
    content = [<FlashCardPage key="flashcard-page"/>]
  }

  return(
    <div className="App">
      {content}
      <Fader fader={AppModel}/>
    </div>
  )
}
export default App;
