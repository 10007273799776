import AppSock from './AppSock'
import DeckModel from './DeckModel'
import Annotator from './Annotator'

var AppModel = new(function(){
    this.page = "start"
    var params = {}
    window.location.search.substring(1).split("&").forEach(x=>{
        var k = x.split("=")
        params[k[0]] = k[1]
    })
    console.log(params)
    if(params['study_id'] !== undefined){
        var sid = params['study_id']
        this.page = "interact"
        setTimeout(()=>{
            AppSock._uid = sid
            DeckModel.init(sid, ()=>{
                DeckModel.moreCards()
            })
        }, 50)
        setTimeout(()=>{
            window.update()
        }, 100)
    }

    this.startPageProps = {
        onInputFocus: ()=>{},
        onInputDefocus: ()=>{},
        startReading: ()=>{}
    }

    this.getInputText = ()=>{
        var inputDom = document.getElementById("inputText")
        return inputDom.value;
    }

    if(params['label'] === "true"){
        console.log("ANNOTATE")
        Annotator.init()
    }
})()

window.AppModel = AppModel
export default AppModel