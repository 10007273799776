import AppSock from './AppSock'
import CardModel from './CardModel'
import QuizModel from './QuizModel'
var DeckModel = new (function(){
  // View Properties
  this.display = "catalog";
  // setTimeout(()=>{
  //   DeckModel.changeMode("quiz")
  //   setTimeout(()=>{
  //     window.update()
  //   }, 600)
  // }, 180)
  this.animate = true
  this.showAlpha = false;

  //subscriptions
  this.subscriptions = {
    'definitions': true,
    'facts': true,
    'googleImages': true,
    'wikiLinks': false
  }

  //Deck Properties
  this.name = "Fetching Test..";
  this.bufferCard = -1;
  this.lastCalibration = Date.now()

  //binary states
  this.loading = true;
  this.draggingCard = null;

  //Card Groups
  this.full_deck = []
  this.trashed_cards = []
  this.cards = [];
  this.focusCard = null;
  this.quiz = null;
  this.hasTrash = false;
  this.BATCH_SIZE = 10
  this.init = (deckID, cb)=>{
    if(deckID === undefined){
      return
    }

    console.log("DECK MODEL INITING")
    console.log(deckID)
    AppSock.getStudy(deckID, (data)=>{
      console.log(data);
      this.name = data.deck.name;
      this.sentences = data.deck.sentences;
      this.card_map = {}
      var deck_data = Object.keys(data.deck.cards).map(k=>data.deck.cards[k])
      deck_data.sort((a,b)=>b.linker_score - a.linker_score)
      deck_data.sort((a,b)=>b.references - a.references)
      deck_data.sort((a,b)=>b.ranker_score - a.ranker_score)

  
      console.log(deck_data.length)
      this.full_deck = []
      var dlen = deck_data.length
      for(var i=0; i<Math.min(this.BATCH_SIZE, dlen); i++){
        var d = deck_data.shift()
        // var x = i
        // if(!INSTANT_DECK_LOAD){
        //   x = -6
        // }
        var card = new CardModel(d, -6)
        this.full_deck.push(card)
        this.card_map[d['card_id']] = card
      }
      
      while(deck_data.length > 0){
        let d = deck_data.shift()
        let card = new CardModel(d, -6)
        this.full_deck.push(card)
        this.card_map[d['card_id']] = card
      }
      this.loading = false;
      AppSock._uid = deckID;
      this.quiz = new QuizModel(this)
      window.update()
      if(cb) cb()
    })
  }



  this.moreCards = ()=>{
      var dlen = this.full_deck.length
      for(var i=0; i<Math.min(this.BATCH_SIZE, dlen); i++){
        setTimeout(()=>{
          this.cards.push(this.full_deck.shift())
          //console.log("added")
          setTimeout(()=>{
            this.recalibrate()
            setTimeout(()=>{
              window.update()
            }, 300)
          }, 10)
          window.update()
        }, i*200)
    }
  }


  this.changeMode = (newMode)=>{
    this.fade(()=>{
      if(this.focusCard){
        this.focusCard.unflip()
        this.focusCard = null;
      }
      if(newMode === "study" && this.cards.length > 0){
        this.focusCard = this.cards[0]
      }

      this.display = newMode
      console.log("SWITCH TO "+newMode)
      window.update()
      setTimeout(()=>{
        window.update()
      }, 100)
    });
  }


  this.hidePopUp = ()=>{
     if((this.display === "catalog" || this.display === "study") && this.focusCard){
       this.focusCard.unflip()
       this.focusCard.flipped = false;
       if(this.display === 'catalog'){
        this.focusCard = null;
       }
       window.update()
    }
   }

  this.openPopUp = (card, cb)=>{
      DeckModel.focusCard = card;
      card.open();
      if(cb){cb()}
      window.update()
  }

  this.flipFocus = ()=>{
    if(this.focusCard){
      if(this.focusCard.flipped){
        this.hidePopUp();
      }else{
        this.openPopUp(this.focusCard, this.focusCard.flipCB)
      }
    }
  }


  this.prevCard = ()=>{
    if(this.display !== "study") return
    if(!this.focusCard) return
    if(this.cards.length === 0) return

    this.cards[this.cards.length-1].nextAnim(()=>{
      var bottomCard = this.cards.pop()
      this.cards.unshift(bottomCard)
      this.focusCard = this.cards[0]
      window.update()
    })
  }

  this.nextCard = ()=>{
    if(this.display !== "study") return
    if(!this.focusCard) return
    if(this.cards.length === 0) return

    this.focusCard.nextAnim(()=>{
      var topCard = this.cards.shift()
      this.cards.push(topCard)
      this.focusCard = this.cards[0]
      window.update()
    })
  }

  this.getTrashCards = ()=>{
    return this.cards.filter(c=>c.trashed)
  }

  this.recalibrate = ()=>{
    this.lastCalibration = Date.now();
    var k = 0
    for(var i=0; i<this.cards.length; i++){
      var card = this.cards[i]
      if(!card.trashed){
        card.position = k;
        k += 1
        card.calcPoses();
      }
    }
    window.update()
  }

  this.reOrderCards = ()=>{
    console.log(this.draggingCard, this.bufferCard)
    if(!this.draggingCard || this.bufferCard === -1 || this.draggingCard.position === this.bufferCard){
      this.recalibrate();
      return;
    }

    var newCards = []
    var i = 0
    while(this.cards.length > 0){
      var card = this.cards.shift()
      if(card === this.draggingCard){
        //i += 1
        continue
      }
      if(i === this.bufferCard){
        newCards.push(this.draggingCard)
      }
      newCards.push(card)
      i += 1
    }

    if(i === this.bufferCard){
      newCards.push(this.draggingCard)
    }
    
    console.log(newCards)

    for(i=0; i<newCards.length; i++){
      newCards[i].position = i
    }

    this.cards = newCards;
    this.bufferCard = -1;
    this.draggingCard = null;
    this.recalibrate();
  }

  this.trashCard = (card)=>{
      card.trashed = true;
      this.recalibrate()
      setTimeout(()=>{
        this.hasTrash = true
        this.trashed_cards.push(card)
        this.cards = this.cards.filter(x=>!x.trashed)
        this.recalibrate()
        window.update();
      }, 950)
      window.update();
  }


  this.updateFocusedCard = (fixHeight)=>{
    this.curCard = this.selectedCards[0];
    if(this.selectedCards.length>1){
      this.backCard = this.selectedCards[1];
    }
    if(fixHeight && this.deck && this.deck.refs.curCard){
      if(this.deck.refs.backCard){
        this.deck.refs.backCard.fixHeight();
      }
      this.deck.refs.curCard.fixHeight();
    }
  }

})();



window.DeckModel = DeckModel
export default DeckModel