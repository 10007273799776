import React, { Component } from 'react';
import GlobalValues from '../../../models/GlobalValues'
import AppModel from '../../../models/AppModel'
class InputBox extends Component {
      constructor(props){
        super(props);
        this.state = {
            charsLeft: GlobalValues.MAX_CHAR_LIMIT,
            style:{
                opacity:0,
                transform: 'translateX(-160px)'
            }
        }
        setTimeout(()=>{
            this.setState({
                style:{
                    opacity:1
                }
            })
        }, 400)
      }
      
      render() {
        return (
            <div className="input-box-container" style={this.state.style}>
                <textarea className="input-textarea" id="inputText" name="plaintext" maxLength={GlobalValues.MAX_CHAR_LIMIT} 
                    onInput={()=>{
                        var inputDom = document.getElementById("inputText")
                        var charsLeft = GlobalValues.MAX_CHAR_LIMIT-inputDom.value.length;
                        this.setState({'charsLeft': charsLeft})
                    }}
                    onFocus={AppModel.startPageProps.onInputFocus} 
                    onBlur={AppModel.startPageProps.onInputDefocus}
                    placeholder="Paste content here and start studying better, faster, stronger." >
                </textarea>
                <div className="char-counter">
                    characters left: {this.state.charsLeft}
                </div>
                
            </div>
        )
      }
 }

export default InputBox
