import React from 'react';
class Fader extends React.Component  {
      constructor(props){
        super(props);
        this.state = {
            fade: false,
            opacity: 0
        }
        this.props.fader.fade = (cb)=>{
            this.setState({
                fade: true,
            })
            setTimeout(()=>{
                this.setState({
                    opacity: 1
                })
            }, 10)

            setTimeout(()=>{
                this.setState({
                    opacity:0
                })
                if(cb){cb()}
                setTimeout(()=>{
                    this.setState({
                        fade: false
                    })
                }, 500)
            },500)
        }
      }

      render(){
        if(!this.state.fade) return null
        return (
          <div className="fader" style={{opacity:this.state.opacity}}></div>
        )
      }
 }

 export default Fader