import React from 'react';
import DeckModel from '../../../models/DeckModel'
import GlobalValues from '../../../models/GlobalValues'
import question from '../../../assets/images/delete.png'
import loading_gif from '../../../assets/images/box.gif'
import MyDraggable from '../parts/MyDraggable'
class TinyCard extends React.Component {
      constructor(props){
        super(props)
        this.topic = this.props.data.topic
        this.state = {
          className:"",
          tinyTextW:-1,
          tinyTextH:-1,
          fontSize: this.getBestFontSize(this.topic),
          anim: null,
          containerStyle:{},
          contentStyle:{},
          cardStyle:{},
          trashIconStyle:{},
          position: 'front',
          fullImage: false
        }
        this.grabTimer = null;
        this.grabStarted = 0;
        this.lastDrag = Date.now();
        this.props.data.unflip = ()=>{
          this.state.containerStyle.zIndex = 9999;
          
          // this.setState({position: 'flipping-out'})
          // setTimeout(()=>{
            this.setState({position: 'front'})
            setTimeout(()=>{
              var cStyle = {...this.state.containerStyle}
              cStyle.zIndex = 0
              this.setState({
                containerStyle:cStyle
              })
            }, 150)
          // }, 5)
          
        }

        this.props.data.blink = ()=>{
          console.log("OOO")
          var curCardStyle = this.state.cardStyle
          curCardStyle.background = "#f9f9f9"
          this.setState({cardStyle: curCardStyle})
        }
      }

      getBestFontSize(topic){
        if(!topic) return 0
        var zoom = 1.4 //window.devicePixelRatio;
        var fontSizeFromWordLength = (len)=>{
          if(len < 16){
            return 1.5
          }else if (len < 24){
            return 1.25
          }else if (len < 36){
            return 1.1
          }
          return .5
        }
        this.words = topic.split(" ")
        if(this.words.length === 1){
          let size = fontSizeFromWordLength(topic.length)*zoom
          return "min("+size+"vw, "+(size*1.1)+"vh)"
        }else{
          var longestWord = Math.max(...this.words.map((w)=>{return w.length}));
          let size = fontSizeFromWordLength(longestWord)*zoom
          return "min("+size+"vw, "+size*1.1+"vh)"
        }
      }


      trash(){
        var card = this.props.data;
        console.log("TODO: Trash")
        var coords = card.getCoords()
        var endX = coords.cWidth - coords.cWidth/5
        var xOff = Math.floor(endX - coords.x)
        var r = [0,0,1, Math.floor(Math.random()*300)+200]
        var y = coords.y  - this.scrollOffsetY()
        var thrust = Math.max(coords.cHeight/20,  y/2)
        var endY = coords.cHeight*1.5 - y
        // Handle x Axis
        this.setState({
          containerStyle:{
              zIndex: 999,
              transition: 'all 1s',
              transitionTimingFunction: 'ease-out',
              transform: 'translateX('+xOff+'px)',
          },
          cardStyle:{
            transition: 'all 1s',
            transitionTimingFunction: 'ease-out',
            transform: 'scale(.3) rotate3d('+r[0]+','+r[1]+','+r[2]+','+r[3]+'deg)'
          },
          contentStyle:{
            transition: 'all .35s',
            transitionTimingFunction: 'ease-out',
            transform: 'translateY(-'+thrust+'px)'
          },
          trashIconStyle:{
            opacity: 0
          }
        })   
        // this.props.data.graphics.x = 800;
        // this.props.data.graphics.y = 300;
        //DeckModel.trashCard()
        setTimeout(()=>{
          this.setState({
            contentStyle:{
              transition: 'all .65s',
              transitionTimingFunction: 'ease-in',
              transform: 'translateY('+endY+'px)'
            },
          })
        }, 350)

        setTimeout(()=>{
          this.setState({
            containerStyle:{
              display: 'none'
            },
          })
        }, 1000)

        DeckModel.trashCard(card)

      }

      scrollOffsetY(card){
        var parent = document.getElementById("cardParent")
        // var myHeight = 0
        // if(card){
        //   var cardEl = document.getElementById("card-"+card.card_id)
        //   myHeight = cardEl.getBoundingClientRect().height
        // }
        if(!parent){
          return 0;
        }
          return parent.scrollTop //+ myHeight/2
      }
     




      renderFront(card, coords){
        var topic = card.topic;
        var cardStyle = {...this.state.cardStyle}
        cardStyle.borderRadius = coords.borderRadius
        if(card.facts !== null || DeckModel.display === "study") cardStyle.background = "#f9f9f9"
        //console.log(card.topic, this.state.contentStyle)
        return(
            <div className='tiny-card-content front' style={this.state.contentStyle}>
              <div className='tiny-card-content' style={this.state.contentStyle}>
                <img alt='delete' onClick={this.trash.bind(this,card)} src={question} className="tiny-card-question-btn" style={this.state.trashIconStyle}/>
                <div 
                onClick={()=>{
                  if(this.grabTimer){
                    this.grabTimer = null;
                    return;
                  }
                  DeckModel.openPopUp(card)
                  this.setState({
                    position:'flipping'
                  })

                }} className="tiny-card" style={cardStyle}>
                  <div className="text-padding-top"></div>
                    <div style={{fontSize:(this.getBestFontSize(topic))}} className="tiny-card-text noselect">
                      {topic}
                    </div>
                  <div className="text-padding-bottom"></div>
                </div>
              </div>
            </div>

        )
      }

      renderFullImage(card){
        if(!this.state.fullImage) return null;
        return(
          <div className='full-image' onClick={()=>{
            this.setState({fullImage:false})
          }}>
            <img alt='visual' src={card.images[card.img_idx]}/>
          </div>
        )
      }

      renderImages(card){
        //console.log(card)
        if(card.images === null) return null;
        if(card.images.length === 0){
          return (
            <div className='pic-container'>
                  <img alt='loading' src={loading_gif} className='loading-img'/>
            </div>
          )
        }
        return (
          <div className='pic-container no-bg' contentEditable="false" suppressContentEditableWarning={true}>
              <div className='left-img-action' onClick={()=>{
                  card.img_idx -= 1
                  if(card.img_idx === -1){
                    card.img_idx = card.images.length - 1;
                  }
                  this.setState({})
              }}></div>
              <div className='center-img-action' onClick={()=>{
                this.setState({fullImage:true})
              }}></div>
              <div className='right-img-action' onClick={()=>{
                  card.img_idx += 1
                  if(card.img_idx === card.images.length){
                    card.img_idx = 0
                  }
                  this.setState({})
              }}></div>
              <img alt='visual' src={card.images[card.img_idx]} className='card-img'/>
          </div>
        )
      }

      renderFacts(card){
        if(!card.facts) return null
        //if(card.facts.length == 0) return null
        var facts = card.activeFacts;
        var factTitle = facts.length > 0?(<div className="bolded-def">Facts:</div>):null;
        var k = 0
        var factStyle = {}
        if(facts.length > 8){
          factStyle = {
            fontSize: '.4em',
            lineHeight: '1.4em'
          }
        }
        return(
          <div className='card-facts'>
            {factTitle}
            <div className='facts-container'>
              {this.renderImages(card)}
                {facts.map(fact=>{
                  k += 1
                  return(
                    <div className='fact' key={'fact-'+k} style={factStyle}>
                      {'• '+fact[0]}<br></br>
                    </div>
                  )
                })}

            </div>
            
          </div>
        )
      }

      renderDefinition(card){
        if(card.definition === "No definition found") return null;
        var defSize = ".6em"
        if(card.definition.length > 200){
          defSize = '.4em'
        }
        return(
            <div className="card-definition">
              <div className="bolded-def" style={{fontSize:defSize}}>
                Definition:
              </div>
              <div className="definition" style={{fontSize:defSize}}>
                  {card.definition}
              </div>
            </div>
        )
      }

      renderBack(card, coords){
        var topic = card.topic;
        var cardStyle = {...this.state.cardStyle}
        if(card.facts !== null || DeckModel.display === "study") cardStyle.background = "#f9f9f9"
        cardStyle.borderRadius = coords.borderRadius
        return (
          <div className={"tiny-card-content back"} onClick={(e)=>{e.stopPropagation()}}>
            <div className='tiny-card-back' style={cardStyle}>
              <div className="topic-text" contentEditable={true} id={'card-title-'+card.card_id} suppressContentEditableWarning={true} onInput={()=>{
                var titleEl = document.getElementById('card-title-'+card.card_id)
                if(titleEl){
                  var val = titleEl.innerHTML;
                  if(val.length === 0){
                    val = " "
                  }
                  card.topic = val
                }
              }}>
                {topic}
              </div>
              <div className="card-content" contentEditable={true} suppressContentEditableWarning={true}>
                  {this.renderDefinition(card)} 
                  {this.renderFacts(card)}
              </div>
            </div>
            {this.renderFullImage(card)}
          </div>
        )
      }


      render(){
        var card = this.props.data;        
        if(!card.topic) return null;
        var coords = card.getCoords()
        if(!coords) return null;
        var containerStyle = {...this.state.containerStyle}
        containerStyle.left = coords.x
        containerStyle.top = coords.y
        containerStyle.width = coords.width
        containerStyle.height = coords.height

        var extraStyle = ""
        var front = this.renderFront(card, coords)
        var back = this.renderBack(card, coords)
       

        if(this.state.position === 'flipping'){
          var offsetY = this.scrollOffsetY(card);
          extraStyle = " flipped"
          containerStyle.transform = "rotateY(180deg) scale(3)"
          containerStyle.transitionTimingFunction = 'ease-out'
          containerStyle.zIndex = 9000;
          containerStyle.left = coords.cWidth/2 - coords.cWidth/10;
          containerStyle.top = coords.cHeight/2 - coords.cHeight/10 + offsetY;
        }
        return (
          

                <div 
                    className={"tiny-card-container"+extraStyle} 
                    style={containerStyle} 
                    id={'card-'+card.card_id} 
                    // onMouseOver={(e)=>{
                    //   if(this.grabTimer){
                    //     return;
                    //   }
                    //   if(DeckModel.draggingCard){
                    //     var diff = Date.now() - DeckModel.lastCalibration;
                    //     //console.log("new buffer: ", card.position)

                    //     if(diff > 80){
                    //       DeckModel.bufferCard = card;
                    //       DeckModel.recalibrate();
                    //       //console.log("recalled")
                    //     }
                    //   }
                    // }}
                    onMouseDown={()=>{
                        this.grabStarted = Date.now()
                        this.grabTimer = setTimeout(()=>{
                          console.log("switching")
                          var newContainerStyle = {...containerStyle}
                          newContainerStyle.cursor = "move"
                          newContainerStyle.transition = "all 0s"
                          newContainerStyle.zIndex = 9000;
                          newContainerStyle.pointerEvents = "none"
                          var newContentStyle = {...this.state.contentStyle}
                          newContentStyle.transition = "all 0s"
                          newContentStyle.pointerEvents = "none"
                          var newCardStyle = {...this.state.cardStyle}
                          newCardStyle.transition = "all 0s"
                          newCardStyle.pointerEvents = "none"
                          console.log("HITIS")
                          this.setState({containerStyle: newContainerStyle, contentStyle:newContentStyle, cardStyle:newCardStyle})
                          DeckModel.draggingCard = card;
                        }, 500)
                    }}
                  >
                    <MyDraggable 
                    position = {{x:0, y:0}}
                    onDrag={(e)=>{
                      // Get Grid Position from mouse pos
                      var now = Date.now();
                      if(now - this.lastDrag < 150 || now - this.grabStarted < 150){
                        return
                      }
                      this.lastDrag = Date.now()
                      var container = document.getElementById("cardContainer")
                      if(container == null){
                        return null;
                      }
                      var bounds = container.getBoundingClientRect()
                      var cWidth = bounds.width;
                      var unit = (cWidth/1000)//*zoom;

                      var width = GlobalValues.CARD_WIDTH
                      var height = GlobalValues.CARD_HEIGHT
                      var leftMargin = GlobalValues.LEFT_MARGIN;
                      var topMargin = GlobalValues.TOP_MARGIN
                      var cpr = GlobalValues.CARD_PER_ROW
                      var mouseX = e.clientX - bounds.left;
                      var mouseY = e.clientY - bounds.top
                      var relX = Math.floor(mouseX / unit) - leftMargin
                      var relY = Math.floor(mouseY / unit) - topMargin
                      var xPos = Math.floor(relX/width)
                      var yPos = Math.floor(relY/height)
                      var idx = yPos*cpr
                      idx += xPos
                      if(idx >= DeckModel.cards.length || idx < 0){
                        idx = -1
                      }
                      //console.log(mouseX, relX, xPos, idx)
                      console.log(mouseY, relY, yPos, idx)
                      //console.log(idx)
                      DeckModel.bufferCard = idx;
                      DeckModel.recalibrate();
                    }}
                    onStop={(e)=>{
                      var now = Date.now();
                      console.log("diff: ", now - this.grabStarted)
                      if(now - this.grabStarted < 200){
                        clearTimeout(this.grabTimer)
                        this.grabTimer = null;
                        DeckModel.draggingCard = null;
                        return
                      }
                      console.log("drag stopped")
                      DeckModel.reOrderCards()
                      DeckModel.draggingCard = null;
                      setTimeout(()=>{
                        this.setState({containerStyle: {}, contentStyle:{}, cardStyle:{}})
                      },300)
                    }}>

                      {front}
                    </MyDraggable>
                        {back}
                  </div>
                  
                )
      }
 }

export default TinyCard