
import {hashString} from '../libs/utils'
import Endpoints2 from '../models/Endpoints2'

var qa_hash = {}


class Question{
    constructor(fact, card, bans_map){
        this.text = fact[0]
        this.qid = hashString(this.text)
        var _salience_bonus = card.salience?card.salience:0
        var _linker_bonus = card.linker_score?card.linker_score:0
        this.score = (1 + fact[1])*8 + _salience_bonus*3 + _linker_bonus 
        this.answer = card.topic
        this.alts = []
        this.type = card.type
        this.distractors = null
        this.generate_distractors = ()=>{
            var key = this.text+"|"+this.answer
            if(qa_hash[key]){
                //console.log('using hash')
                this.distractors = qa_hash[key]
            }else{
                Endpoints2.getDistractors({question:this.text, answer:this.answer}, (res)=>{
                    if(res.status === 200){
                        this.distractors = res.distractors;
                        //console.log(this.answer, this.distractors)
                    }else{
                        this.distractors = []
                    }
                    qa_hash[key] = this.distractors;
                    window.update();
                })
            }
           
        }
        
        // var bans = bans_map[this.answer]
        // if(bans){
        //     this.mpc = true;
        //     this.type = bans.type
        //     this.alts = bans.alts.map(x=>this.answer.replace(this.concept, x).replace("_"," ")).filter(x=>x!==this.answer)
        // }else{
        //     this.type = "unknown"
        //     this.mpc = false;
        // }
    }
}

class QuizModel{
    constructor(deck){
        this.BATCH_SIZE = 8;
        this.deck = deck
        this.question_bank = []
        this.questions = []
        this.bans_map = {}
        this.constructQuiz()
    }

    constructQuiz(){
        var questions = []
        var _cards = this.deck.cards.concat(this.deck.full_deck)
        _cards.forEach(card=>{
            if(card.facts){
                card.facts.forEach(f=>{
                    questions.push(new Question(f, card, this.bans_map))
                })
            }
        })

        questions = this.downRankRepetative(questions)
        questions.sort((a,b)=>{ return b.score - a.score })
        //Interlace Numerics
        for(var i=0; i<questions.length-1; i++){
            var q = questions[i]
            if(q.type === "numeric"){
                if(questions[i+1].type === "numeric"){
                    //find next one that isn't numeric
                    var next_non_numeric = null;
                    for(var j=i+2; j<questions.length; j++){
                        var cq = questions[j]
                        //console.log(cq)
                        if(cq.type !== "numeric"){
                            next_non_numeric = cq
                            break
                        }
                    }

                    if(next_non_numeric){
                        questions[j] = questions[i+1];
                        questions[i+1] = cq
                    }
                }
            }
        }
        //console.log("total Qs: "+questions.length)
        //console.log(questions)
        this.questions = []
        this.question_bank = questions
        //this.moreQuestions()
           
    }

    downRankRepetative(qbank){
        var seen_weights = {}
        for(var i=0; i<qbank.length; i++){
            var q = qbank[i]
            var concept = q.answer;
            if(q.type === "numeric"){
                //console.log("biased ", q)
                q.score = q.score *1.1
            }
            if(seen_weights[concept] !== undefined){
                q.score = q.score*seen_weights[concept]
            }else{
                seen_weights[concept] = 1
            }
            seen_weights[concept] = seen_weights[concept]*.8
        }
        return qbank
    }

    moreQuestions(){
        for(var i=0; i<this.BATCH_SIZE; i++){
            var new_question = this.question_bank.shift()
            if(new_question){
                new_question.generate_distractors()
                this.questions.push(new_question)
            }
        }
        window.update();
    }
}

export default QuizModel
