import React, { Component } from 'react';
class LoadingButton extends Component {
      constructor(props){
        super(props);
        this.state = {
            ticks: 5,
            mounted: false
        }
        if(!this.props.dynamic){
            var interval = setInterval(()=>{
                if(this.state.ticks < this.props.delay){
                    if(this.state.mounted){
                        this.setState({ticks: this.state.ticks+1})
                    }
                }else{
                    clearInterval(interval)
                }
            }, 50)
        }
      }

      componentDidMount(){
          this.setState({mounted:true})
      }

      render(){
          var bar = this.props.dynamic?this.props.value.cur:this.state.ticks
          var total = this.props.dynamic?this.props.value.total:this.state.delay
          var style = this.props.style?this.props.style:{}
          var title = this.props.onLoadTitle?this.props.onLoadTitle:this.props.title
          if(bar < total){
            var perc = (bar / total)*100
            return (
                <div className='loading-btn-container' style={style}>
                    <div className='loading-btn-bar' style={{width: perc+"%"}}></div>
                    <div className='loading-btn-title'> {title} </div>
                    <div className='hidden-title'> {title} </div>
                </div>
            )
          }else{
            return (
                <div className='loading-btn' id='viewFlashCards' onClick={this.props.onClick} style={style}>
                    {this.props.title}
                </div>
              )
          }
      }
 }

export default LoadingButton
