import React, { Component } from 'react';
import CrashHandler from '../../../models/CrashHandler'
import AppSock from '../../../models/AppSock'
import GlobalValues from '../../../models/GlobalValues';
import AppModel from '../../../models/AppModel';
import {getStartAnimationCtx} from '../../../libs/utils'

class SubmitBtn extends Component {
      constructor(props){
        super(props);
        this.state = {
            style:{
                opacity:0,
                transform: 'translateX(180px)'
            },
            btnStyle:{
                backgroundColor: "rgb(93 135 179)"
            }
        }
        var colors = ['rgb(179 93 93)','rgb(179 93 173)','rgb(93 135 179)','rgb(93 179 179)','rgb(98 179 93)','rgb(178 179 93)']
        var k = 2
        this.colorInterval = setInterval(()=>{
            this.setState({
                btnStyle: {
                    backgroundColor: colors[k%5]
                }
            })
            k += 1
        }, 1000)
      }

      componentWillUnmount() {
          clearInterval(this.colorInterval)
      }

      
      render() {
        //this.state.style.display = "none"
        var ctx = getStartAnimationCtx()
        var style = this.state.style;
        if(ctx && ctx.currentFrame === 29){
            var val = AppModel.getInputText()
            if(val.length > GlobalValues.MIN_LENGTH_INPUT){
                style = {
                    opacity: 1
                }
            }
        }

        return (
            <div style={style} className='submition-button'>
                <button id='submitBtn' style={this.state.btnStyle} className="upload-btn source-btn" onClick={()=>{
                        var inputDom = document.getElementById("inputText")
                        var submitBtn = document.getElementById("submitBtn")
                        var txt = inputDom.value
                        if(txt.length > GlobalValues.MIN_LENGTH_INPUT){
                            submitBtn.style.backgroundColor = "rgb(160, 165, 160)";
                            submitBtn.style.cursor='default';
                            //InputHandler.supressInputs = true;
                            AppSock.sendSource(CrashHandler.removeCrashableStrings(txt))
                            window.scrollElTo("inputText",0,5)
                            AppModel.startPageProps.startReading();
                            GlobalValues.submitted = true
                            setTimeout(()=>{ 

                                var loading_container = document.getElementById("loadingButtonsContainer")
                                loading_container.style.opacity = 1 
                            }, 20)
                            window.update();
                        }else{
                            //alert("please submit a longer input")
                        }
                    }}>
                    Let's Go!
                </button>
            </div>
            
        )
      }
 }

export default SubmitBtn
