import Draggable from 'react-draggable';
class MyDraggable extends Draggable {
    onStop = (e)=>{
        super.onStop(e)
        console.log("STIPFJIE")
        console.log(this)

    }
}
export default MyDraggable
