const GlobalValues = {
  ALLOW_NON_MEDICAL: true,
  HOST_IP: "35.202.95.47",
  MAX_QUESTION_LENGTH: 250,
  MAX_ANSWER_LENGTH: 250,
  MAX_CHAR_LIMIT: 100000,
  submitted: false,
  CARD_PER_ROW: 4,
  QG_DEPTH_LIMIT: 35,
  MIN_LENGTH_INPUT: 12,
  DISCORD_LINK: "https://discord.gg/GBX3f6Y",
  CARD_WIDTH: 200,
  CARD_HEIGHT: 120,
  LEFT_MARGIN: 100,
  TOP_MARGIN: 30
}

export default GlobalValues