import React from 'react';
import checkmark from '../../../assets/images/checkmark.png'
import xMark from '../../../assets/images/redo.png'
import loading from '../../../assets/images/ring-alt.svg'
class EditableAnswer extends React.Component  {
      constructor(props){
        super(props);
        this.state = {
            solved: false
        }
      }

      checkCorrect(){
          //   var val = e.target.value
          //   var dist = Levenshtein.get(val, this.props.answer)
          //   var thresh = 2;
          //   if(this.props.answer.length < 6){
          //       thresh = 1
          //   }
          //   if(this.props.answer.length <= 3){
          //       thresh = 0
          //   }

          //   if(dist <= thresh){
          //     this.setState({solved:true})
          //   }
}

      render(){
        // if(this.state.solved){
        //     var ans = '✔'+this.props.answer
        //     return (
        //         <div className='answer-container correct-fib'>
        //             {ans}
        //         </div>
        //         )
        // }
        var d = this.props.question.distractors;
        var distractors = (<img alt='loading' src={loading} className='quiz-distractor-loading'/>)
        if(d !== null){
          var i = 0
          distractors = d.map((x)=>{
            var idx = i
            i += 1
            var identifier = this.props.question.qid+"-"+idx
            return (
              <div className='distractor-container' key={identifier}>
                <img  alt='xmark' src={xMark} className='quiz-xmark' onClick={()=>{
                  var cur_el = d[idx]
                  d[idx] = d.splice(3,1)[0]
                  d.push(cur_el)
                  var el = document.getElementById(identifier)
                  el.value = d[idx]
                }}/>
                <input type='text' id={identifier} className='quiz-fib-distractor' defaultValue={x} onChange={(e)=>{
                  d[idx] = e.target.value;
                }}/>
              </div>
            )
          })
          distractors = distractors.slice(0, 3)
        }

        return (
          <div className='answers-container'>
            <div className='answer-container'>
              <img alt='checkmark' src={checkmark} className='quiz-checkmark'/>
              <input type='text' id={'answer-'+this.props.question.qid}  className='quiz-fib' defaultValue={this.props.question.answer} onChange={this.checkCorrect.bind(this)}/>
            </div>
            <div className='distractors-container'>
              {distractors}
              
            </div>
          </div>
            
          )
      }
 }

 export default EditableAnswer