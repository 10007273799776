import React from 'react';
import upArrow  from '../../../assets/images/up-arrow.png'
import downArrow from '../../../assets/images/down-arrow.png'
import AppSock from '../../../models/AppSock'
class QuestionLabel extends React.Component  {
      constructor(props){
        super(props);
        this.state = {
            upClass:"",
            downClass:""
        }
      }
      

      render(){
        return (
            <div className='annie-q-rate'>
                <img alt='up' src={upArrow} className={'up-arrow '+this.state.upClass} onClick={()=>{
                    var el = document.getElementById("question-"+this.props.question.qid)
                    var el2 = document.getElementById("answer-"+this.props.question.qid)
                    var question = el.innerText.trim()
                    var answer = el2.value.trim()
                    AppSock.labelQuestion(question, answer,  "good", ()=>{
                        this.setState({upClass:"selected-up", downClass:""})
                    })
                }}/>
                <img alt='down' src={downArrow} className={'down-arrow '+this.state.downClass} onClick={()=>{
                    var el = document.getElementById("question-"+this.props.question.qid)
                    var el2 = document.getElementById("answer-"+this.props.question.qid)
                    var question = el.innerText.trim()
                    var answer = el2.value.trim()
                    AppSock.labelQuestion(question, answer, "bad", ()=>{
                        this.setState({upClass:"", downClass:"selected-down"})
                    })
                }}/>
            </div>
        )
      }
 }

 export default QuestionLabel