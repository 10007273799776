import React, { Component } from 'react';
import AppModel from '../../../models/AppModel'
import GlobalValues from '../../../models/GlobalValues'
import {getStartAnimationCtx} from '../../../libs/utils'

class StartAnimation extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
        var run = ()=>{
            var ctx = getStartAnimationCtx()
            if(ctx){
                ctx.play()
            }else{
                setTimeout(run, 100)
            }
        }

        setTimeout(run, 1000)
        AppModel.startPageProps.onInputFocus = ()=>{
            var ctx = getStartAnimationCtx()
            if(ctx && ctx.currentFrame === 28){
                ctx.gotoAndStop(29)
                setTimeout(window.update, 150)
            }else{
                if(!ctx || ctx.currentFrame !== 29){
                    setTimeout(AppModel.startPageProps.onInputFocus, 150)
                }
            }
        }

        AppModel.startPageProps.onInputDefocus = ()=>{
            console.log("defocus")
            var val = AppModel.getInputText()
            if(val.length < GlobalValues.MIN_LENGTH_INPUT){
                var ctx = getStartAnimationCtx()
                ctx.gotoAndStop(28)
                setTimeout(window.update, 150)
            }
        }

        AppModel.startPageProps.startReading = ()=>{
            var ctx = getStartAnimationCtx()
            if(ctx && ctx.currentFrame === 29){
                ctx.gotoAndPlay(30)
            }else{
                if(!ctx || ctx.currentFrame < 29){
                    setTimeout(AppModel.startPageProps.startReading, 150)
                }
            }
        }
    }

    render() {
      return (
          <div>
             <iframe
                      title="start_animation"
                      src="upload_mascot/upload_mascot.html"
                      className="start-anim"
                      id="startAnim"
                      scrolling="no"
                      style={{
                        border: 'none',
                        width: 983,
                        height: 820,
                        bottom: 10
                      }}
                />
          </div>
      )
    }
}

export default StartAnimation