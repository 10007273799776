import React from 'react';
class UpdateCountdown extends React.Component  {
      constructor(props){
        super(props);
        this.udt = 1622878775000
        this.udt = 1642253301000
        this.state = {
          timeLeft: this.udt - Date.now()
        }
        setInterval(()=>{
          this.setState({
            timeLeft: this.udt - Date.now()
          })
        }, 1000)
      }

      render(){
        return null
        var tl = this.state.timeLeft
        var days = Math.floor(tl/(60*60*24*1000))
        if(days < 10){
          days = "0"+days
        }

        var hours = Math.floor(tl/(60*60*1000)) % 24
        if(hours < 10){
          hours = "0"+hours
        }

        var minutes = Math.floor(tl/(60*1000)) % 60
        if(minutes < 10){
          minutes = "0"+minutes
        }

        
        var seconds = Math.floor(tl/(1000)) % 60
        if(seconds < 10){
          seconds = "0"+seconds
        }
        

        return (
          <div className='countdown-timer'> 
            Next Update in:  {days}:{hours}:{minutes}:{seconds}
          </div>
        )
      }
 }

 export default UpdateCountdown
