//This class cleans text that may crash metamap.
//This computation should be transfered on to the server in the future.

var CrashHandler = new (function(){
	this.removeCrashableStrings = function(input){
		input = removeWikiNotation(input);
		input = removeWikiContents(input);
		return input;
	}

	//Removes Wikipedia Notation
	function removeWikiNotation(input){
		var wikNot = /(\[)([\w \s])*(\])/g;
		return input.replace(wikNot,"");
	}

	//Remove wiki content section
	function removeWikiContents(input){
		var occc = countOcurrences(input,"[0-9]\\s");
		var occc2 = countOcurrences(input,"\\.\\s");
		if(occc>6 && occc2<2){
			return "Contents removed, it crashes metamap. ";
		}
		return input;//+"."+num_ds+"."+num_sentence;
	}

	//In the future migrate the string removal parsing to one system.
	function countOcurrences(str, value){
	   var regExp = new RegExp(value, "gi");
	   return (str.match(regExp) || []).length;
	}
})()

// String.prototype.replaceAll = function(str1, str2, ignore){
//     return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
// } 

export default CrashHandler