import React from 'react';
import DeckModel from '../../../models/DeckModel'
import loading_gif from '../../../assets/images/box.gif'

class PopCard extends React.Component {
      constructor(props){
        super(props)
        this.topic = this.props.data.topic
        this.state = {
          className:"",
          tinyTextW:-1,
          tinyTextH:-1,
          fontSize: this.getBestFontSize(this.topic),
          anim: null,
          containerStyle:{},
          cardStyle:{},
          contentStyle:{},
          trashIconStyle:{},
          position: 'front',
          fullImage: false
        }
        this.props.data.unflip = ()=>{
          this.state.containerStyle.zIndex = 9999;
            this.setState({position: 'front'})
            setTimeout(()=>{
              var cStyle = {...this.state.containerStyle}
              cStyle.zIndex = 0
              this.setState({
                containerStyle: cStyle
              })
            }, 150)
        }

        this.props.data.flipCB = ()=>{
          this.setState({
            position:'flipping'
          })
        }

        this.props.data.nextAnim = (cb)=>{
          console.log("nxting")
          var parent = document.getElementById("studyContainer")
          var unit = parent.getBoundingClientRect().width/1000;
          var xShift = -0*unit
          var yShift = -170*unit
          var rShift = -60
          this.setState({
            position:'front',
            cardStyle: {
              transition: 'all .4s',
              transitionTimingFunction: 'ease-out',
              transform: "rotate("+rShift+"deg) translate("+xShift+"px, "+yShift+"px)"
            }
          })
          setTimeout(()=>{
            if(cb) cb()

            this.setState({
              position:'front',
              cardStyle: {
                transition: 'all .4s',
                transitionTimingFunction: 'ease-in',
                transform: ""
              }
            })

          }, 400)

        }

        // this.props.data.prevAnim = (cb)=>{
        //   console.log("preving")
        //   var parent = document.getElementById("studyContainer")
        //   var unit = parent.getBoundingClientRect().width/1000;
        //   var xShift = -0*unit
        //   var yShift = -170*unit
        //   var rShift = -60
        //   this.setState({
        //     position:'front',
        //     cardStyle: {
        //       transition: 'all .4s',
        //       transitionTimingFunction: 'ease-out',
        //       transform: "rotate("+rShift+"deg) translate("+xShift+"px, "+yShift+"px)"
        //     }
        //   })
        //   setTimeout(()=>{
        //     if(cb) cb()

        //     this.setState({
        //       position:'front',
        //       cardStyle: {
        //         transition: 'all .4s',
        //         transitionTimingFunction: 'ease-in',
        //         transform: ""
        //       }
        //     })

        //   }, 400)
        // }
        var xNoise = Math.random()*4 - 2
        var yNoise = Math.random()*4 - 2
        var rNoise = Math.random()*4 - 2
        this.noise = " translate("+xNoise+"px, "+yNoise+"px)"
        this.rotNoise = " rotate("+rNoise+"deg)"
      }

      getBestFontSize(topic){
        if(!topic) return 0
        var zoom = 1.4 //window.devicePixelRatio;
        var fontSizeFromWordLength = (len)=>{
          if(len < 16){
            return 1.5
          }else if (len < 24){
            return 1.25
          }else if (len < 36){
            return 1.1
          }
          return .5
        }
        this.words = topic.split(" ")
        if(this.words.length === 1){
          let size = fontSizeFromWordLength(topic.length)*zoom
          return "min("+size+"vw, "+(size*1.3)+"vh)"
        }else{
          var longestWord = Math.max(...this.words.map((w)=>{return w.length}));
          let size = fontSizeFromWordLength(longestWord)*zoom
          return "min("+size+"vw, "+size*1.3+"vh)"
        }
      }


      trash(){
        var card = this.props.data;
        console.log("TODO: Trash")
        var coords = card.getCoords()
        var endX = coords.cWidth - coords.cWidth/5
        var xOff = Math.floor(endX - coords.x)
        var r = [0,0,1, Math.floor(Math.random()*300)+200]
        var y = coords.y  - this.scrollOffsetY()
        var thrust = Math.max(coords.cHeight/20,  y/2)
        var endY = coords.cHeight*1.5 - y
        // Handle x Axis
        this.setState({
          containerStyle:{
              zIndex: 999,
              transition: 'all 1s',
              transitionTimingFunction: 'ease-out',
              transform: 'translateX('+xOff+'px)',
          },
          cardStyle:{
            transition: 'all 1s',
            transitionTimingFunction: 'ease-out',
            transform: 'scale(.3) rotate3d('+r[0]+','+r[1]+','+r[2]+','+r[3]+'deg)'
          },
          contentStyle:{
            transition: 'all .35s',
            transitionTimingFunction: 'ease-out',
            transform: 'translateY(-'+thrust+'px)'
          },
          trashIconStyle:{
            opacity: 0
          }
        })   
        // this.props.data.graphics.x = 800;
        // this.props.data.graphics.y = 300;
        //DeckModel.trashCard()
        setTimeout(()=>{
          this.setState({
            contentStyle:{
              transition: 'all .65s',
              transitionTimingFunction: 'ease-in',
              transform: 'translateY('+endY+'px)'
            },
          })
        }, 350)

        setTimeout(()=>{
          this.setState({
            containerStyle:{
              display: 'none'
            },
          })
        }, 1000)

        DeckModel.trashCard(card)

      }

      scrollOffsetY(card){
        var parent = document.getElementById("cardParent")
        // var myHeight = 0
        // if(card){
        //   var cardEl = document.getElementById("card-"+card.card_id)
        //   myHeight = cardEl.getBoundingClientRect().height
        // }
        if(!parent){
          return 0;
        }
          return parent.scrollTop //+ myHeight/2
      }
     




      renderFront(card){
        var topic = card.topic;
        var cardStyle = {...this.state.cardStyle}
        cardStyle.borderRadius = "1vw" //coords.borderRadius
        if(card.facts !== null || DeckModel.display === "study") cardStyle.background = "#f9f9f9"

        return(
            <div className='tiny-card-content front' style={this.state.contentStyle}>
              {/* <img onClick={this.trash.bind(this,card)} src={question} className="tiny-card-question-btn" style={this.state.trashIconStyle}/> */}
              <div onClick={()=>{
                DeckModel.openPopUp(card)
                this.setState({
                  position:'flipping'
                })
              }} className="tiny-card" style={cardStyle}>
                <div className="text-padding-top"></div>
                  <div style={{fontSize:(this.getBestFontSize(topic)+"vw")}} className="tiny-card-text noselect">
                    {topic}
                  </div>
                <div className="text-padding-bottom"></div>
              </div>
            </div>

        )
      }

      renderFullImage(card){
        if(!this.state.fullImage) return null;
        return(
          <div className='full-image' onClick={()=>{
            this.setState({fullImage:false})
          }}>
            <img alt='card' src={card.images[card.img_idx]}/>
          </div>
        )
      }

      renderImages(card){
        //console.log(card)
        if(card.images === null) return null;
        if(card.images.length === 0){
          return (
            <div className='pic-container'>
                  <img alt='loading' src={loading_gif} className='loading-img'/>
            </div>
          )
        }
        return (
          <div className='pic-container no-bg' contentEditable="false" suppressContentEditableWarning={true}>
              <div className='left-img-action' onClick={()=>{
                  card.img_idx -= 1
                  if(card.img_idx === -1){
                    card.img_idx = card.images.length - 1;
                  }
                  this.setState({})
              }}></div>
              <div className='center-img-action' onClick={()=>{
                this.setState({fullImage:true})
              }}></div>
              <div className='right-img-action' onClick={()=>{
                  card.img_idx += 1
                  if(card.img_idx === card.images.length){
                    card.img_idx = 0
                  }
                  this.setState({})
              }}></div>
              <img src={card.images[card.img_idx]} className='card-img' alt='concept'/>
          </div>
        )
      }

      renderFacts(card){
        if(card.facts === null || card.facts.length === 0) return null
        var factTitle = card.activeFacts.length > 0?(<div className="bolded-def">Facts:</div>):null;
        var k = 0
        return(
          <div className='card-facts'>
            {factTitle}
            <div className='facts-container'>
              {this.renderImages(card)}
                {card.activeFacts.map(fact=>{
                  k += 1
                  return(
                    <div className='fact' key={'fact-'+k}>
                      {'• '+fact[0]}<br></br>
                    </div>
                  )
                })}

            </div>
            
          </div>
        )
      }

      renderDefinition(card){
        if(card.definition === "No definition found") return null;
        var defSize = ".6em"
        if(card.definition.length > 200){
          defSize = '.4em'
        }
        return(
            <div className="card-definition">
              <div className="bolded-def" style={{fontSize:defSize}}>
                Definition:
              </div>
              <div className="definition" style={{fontSize:defSize}}>
                  {card.definition}
              </div>
            </div>
        )
      }

      renderBack(card){
        var topic = card.topic;
        var cardStyle = {...this.state.cardStyle}
        if(card.facts !== null || DeckModel.display === "study") cardStyle.background = "#f9f9f9"
        cardStyle.borderRadius = "1vw"
        return (
          <div className={"tiny-card-content back"} onClick={(e)=>{e.stopPropagation()}}>
            <div className='tiny-card-back' style={cardStyle}>
              <div className="topic-text" contentEditable={true} id={'card-title-'+card.card_id} suppressContentEditableWarning={true} onInput={()=>{
                var titleEl = document.getElementById('card-title-'+card.card_id)
                if(titleEl){
                  var val = titleEl.innerHTML;
                  if(val.length === 0){
                    val = " "
                  }
                  card.topic = val
                }
              }}>
                {topic}
              </div>
              <div className="card-content" contentEditable={true} suppressContentEditableWarning={true}>
                  {this.renderDefinition(card)} 
                  {this.renderFacts(card)}
              </div>
            </div>
            {this.renderFullImage(card)}
          </div>
        )
      }


      render(){
        var card = this.props.data;       
        if(!card.topic) return null;
        var containerStyle = {...this.state.containerStyle}
        var parent = document.getElementById("studyContainer")
        if(!parent) return null;
        var unit = parent.getBoundingClientRect().width/1000;
        var hunit = parent.getBoundingClientRect().height/1000;
        var width = 200*unit
        var height = 120*unit
        containerStyle.left = 400*unit
        containerStyle.top = 280*hunit
        containerStyle.width = width
        containerStyle.height = height
        containerStyle.transform = "scale(2)" + this.noise+this.rotNoise

        var extraStyle = ""
        var front = this.renderFront(card)
        var back = this.renderBack(card)
       

        if(this.state.position === 'flipping'){
          // var offsetY = this.scrollOffsetY(card);
          extraStyle = " flipped"
          containerStyle.transform = "rotateY(180deg) scale(2.2)"
          containerStyle.transitionTimingFunction = 'ease-out'
          containerStyle.zIndex = 9000;
          //containerStyle.left = 300*unit //coords.cWidth/2 - coords.cWidth/10;
          //containerStyle.top = 180*unit //coords.cHeight/2 - coords.cHeight/10 + offsetY;
        }
        return (
                  <div className={"tiny-card-container"+extraStyle} style={containerStyle} id={'card-'+card.card_id} onClick={(e)=>{
                    e.stopPropagation()
                  }}>
                        {front}
                        {back}
                  </div>
                )
      }
 }

export default PopCard