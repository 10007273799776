import DeckModel from './DeckModel'
var Inputs = new (function(){
    this.hotKeysEnabled = true
    // var timeout;
    // function spamHandle(){
    //   if(!DeckModel.speedMode){
    //     if(timeout) {
    //           clearTimeout(timeout);
    //           timeout = null;
    //     }
    //     timeout = setTimeout(()=>{
    //       DeckModel.clearAnims();
    //     }, 1000)
    //   }
    // }
  
    function hotkeys(k){
      if(DeckModel.display !== "study"){
        return 
      }
      //console.log(k.keyCode)
      if(k.keyCode === 27){
        k.preventDefault();
        DeckModel.changeMode("catalog")
      }
      if(k.keyCode === 40){
        k.preventDefault();
        DeckModel.flipFocus();
      }
      if(k.keyCode === 37){
        k.preventDefault();
        DeckModel.prevCard()
      }
      if(k.keyCode === 39){
        k.preventDefault();
        DeckModel.nextCard()
      }
    }
  
    this.enableHotKeys = ()=>{
      this.hotKeysEnabled = true;
      console.log("enabling hotkeys")
      document.addEventListener("keyup", hotkeys);
    }
    this.enableHotKeys();
  
    this.disableHotKeys= ()=>{
        this.hotKeysEnabled = false;
        console.log("disabling hotkeys")
        document.removeEventListener("keyup", hotkeys);
    }
  
  })();

export default Inputs